import { Flex } from '@/components/Common';
import { CompanyType, RouteName, theme } from '@/constants';
import { useGlobalState } from '@/hooks';
import type { IIndividualCarrier } from '@/interfaces';
import { getMarketSlugFromMarketName, replacePath } from '@/utils';
import Image from 'next/image';
import Link from 'next/link';
import Location from 'public/images/icons/location_on.svg'; // original location.svg is not centered
import { Container, Content, Headquarters, HeadquartersContainer, OverlayLink, Title } from './CarrierCard.styles';

interface CarrierCardProps {
  carrier: Partial<IIndividualCarrier>;
}

const CarrierCard: React.FC<CarrierCardProps> = ({
  carrier: { carrierDisplayName, carrierName, carrierLogo, headquarters, slug },
}) => {
  const { getProperImageUrl } = useGlobalState();

  if (!carrierDisplayName && !carrierName) return null;

  const marketSlug = getMarketSlugFromMarketName(headquarters || '', true);
  const marketHref = replacePath(RouteName.Market, [marketSlug]);

  return (
    <Container>
      {slug && <OverlayLink href={replacePath(RouteName.IndividualCarrier, [CompanyType.carriers, slug])} />}
      <Flex>
        {!!carrierLogo?.url && (
          <Image
            src={getProperImageUrl(carrierLogo)}
            alt={carrierDisplayName || carrierName || 'Carrier Logo'}
            height={78}
            width={78}
            style={{
              objectFit: 'cover',
              marginRight: theme.spacing[16],
              borderRadius: 2,
            }}
          />
        )}
        <Content>
          <Title>{carrierDisplayName || carrierName}</Title>
          {headquarters && (
            <HeadquartersContainer>
              <Location />
              <Link href={marketHref ?? '#'}>
                <Headquarters>{headquarters}</Headquarters>
              </Link>
            </HeadquartersContainer>
          )}
        </Content>
      </Flex>
    </Container>
  );
};

export default CarrierCard;
