import { Button, Typography } from '@/components';
import { TextStyles } from '@/constants';
import { ButtonSize, ButtonVariant } from '@/interfaces';
import styled from 'styled-components';

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})(({ theme }) => ({
  color: theme.colors.highEmphasisBlack,
  marginBottom: theme.spacing[16],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

export const CardGridWrapper = styled.div({
  maxWidth: 1140,
  width: '100%',
  margin: '0 auto',
});

export const ViewAllButton = styled(Button).attrs({
  variant: ButtonVariant.Secondary,
  size: ButtonSize.Large,
})(({ theme }) => ({
  width: '100% !important',
  marginTop: theme.spacing[8],
}));
