import { CompanyType, RouteName, theme } from '@/constants';
import { replacePath } from '@/utils';
import type { CardGridProps } from '../../CardGrid.interfaces';
import { CardGridWrapper, Title, ViewAllButton } from '../../CardGrid.styles';
import CarrierCardList from '../../CarrierCardList';

const CenteredCardGrid: React.FC<CardGridProps> = ({ title, data }) => {
  if (!data?.length) return null;

  const href = replacePath(RouteName.Carriers, [CompanyType.carriers]);

  const titleStyle = {
    textAlign: 'center',
  };

  const buttonStyle = {
    marginTop: theme.spacing[24],
  };

  return (
    <CardGridWrapper>
      {title && <Title style={titleStyle}>{title}</Title>}
      <CarrierCardList data={data} columns={3} />
      <ViewAllButton style={buttonStyle} href={href}>
        View All
      </ViewAllButton>
    </CardGridWrapper>
  );
};

export default CenteredCardGrid;
