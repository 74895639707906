import { Typography } from '@/components';
import { Flex } from '@/components/Common';
import { styledText } from '@/components/Typography/Typography.styles';
import { TextStyles } from '@/constants';
import Link from 'next/link';
import styled from 'styled-components';

export const Title = styled.div`
  ${styledText['Heading 3']}
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  width: fit-content;
`;

export const HeadquartersContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > svg {
    flex: none;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 324px;
  }
`;

export const Headquarters = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Container = styled.div(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.colors.pressedBlack}`,
  borderRadius: theme.spacing[4],
  padding: theme.spacing[16],
  wordBreak: 'break-word',
  cursor: 'pointer',

  [`${Headquarters}`]: {
    position: 'relative',
    zIndex: 2,

    ':hover': {
      textDecoration: 'underline',
      color: theme.colors.blue,
    },
  },
}));

export const OverlayLink = styled(Link)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'block',
  width: '100%',
  height: '100%',
  zIndex: 1,

  [`:hover + ${Flex} ${Title}`]: {
    textDecoration: 'underline',
    color: theme.colors.blue,
  },
}));

export const Content = styled.div`
  overflow: hidden;
`;
