import styled from 'styled-components';

export const ListWrapper = styled.div<{ columns: number }>(({ theme, columns }) => ({
  display: 'grid',
  gap: theme.spacing[8],

  [`${theme.mediaBreakpointUp.md}`]: {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  },
}));
