import type { IDataSection } from '@/interfaces';
import CarrierCard from '../CarrierCard';
import { ListWrapper } from './CarrierCardList.styles';

export type CarrierCardListProps = {
  data: IDataSection['carrierList'];
  columns?: number;
};

const CarrierCardList: React.FC<CarrierCardListProps> = ({ data, columns = 2 }) => {
  if (!data) return null;

  return (
    <ListWrapper columns={columns}>
      {data.map((carrier, idx) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <CarrierCard key={idx} carrier={carrier} />
      ))}
    </ListWrapper>
  );
};

export default CarrierCardList;
